.layoutAside {
  flex: 0 0 268px !important;
  max-width: 300px !important;
  min-width: 300px !important;
  width: 300px !important;
}

// .auth-layout {
//   display: flex;
//   flex-direction: column;
//   overflow: auto;
//   height: 100vh;
//   background-color: #2d304a;
// }

