.TokenDataExplorer {
  .select {
    :global {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 8px 0px 0px 8px !important;
      }
    }
  }
}
.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

