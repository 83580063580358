@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('./assets/fonts/IBMPlexSans-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf');
  font-display: swap;
}

.ant-table-thead > tr > th {
  background: #373e62 !important;
  border-bottom: 0 !important;
  color: #ffffffcc !important;
  font-weight: 400 !important;
  font-size: 16px;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #474e6c !important;
}

.ant-table-tbody tr.ant-table-row {
  background: #262d4e !important;
}

.ant-table-tbody > tr > td {
  border-bottom: 0 !important;
  color: #ffffff !important;
  font-weight: 400 !important;
  font-size: 14px;
}

.ant-table-cell-ellipsis {
  white-space: initial !important;
  text-overflow: initial !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #2d304a !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: transparent !important;
  border: 0 !important;
  color: #ffffff;
}

.ant-pagination-item {
  background-color: transparent !important;
  border: 0 !important;
}

.ant-pagination-item-active {
  background: rgba(255, 255, 255, 0.05) !important;
  border-radius: 8px !important;
}

.ant-pagination-item a {
  color: #ffffff !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: #ffffff !important;
}

.ant-select-selector {
  height: 40px !important;
  color: #ffffff;
}
.ant-select-arrow {
  color: #ffffff66 !important;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
  border: 1px solid #ffffff66 !important;
  border-radius: 8px !important;
}

.ant-tooltip-arrow-content::before {
  background: #313857 !important;
}

.ant-table-placeholder .ant-table-cell {
  background: #262d4e !important;
}

.ant-empty-normal {
  color: #ffffff !important;
}

.ant-table-cell ul {
  background-color: transparent !important;
}

.ant-select-dropdown {
  background-color: #42496f !important;
  border-radius: 8px !important;
  color: #ffffff !important;
}

.ant-select-item {
  background-color: #42496f !important;
  /* border-radius: 8px !important; */
  color: #ffffff !important;
}

.ant-select-item-option-selected {
  color: #ffffff !important;
  background-color: #303961 !important;
}

td.ant-table-column-sort {
  background: #474e6ac6 !important;
}

.ant-table-filter-dropdown {
  background-color: #42496f !important;
}

.ant-dropdown-menu {
  background-color: #42496f !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: #ffffffe5 !important;
  font-size: 12px !important;
}

.ant-table-filter-dropdown-btns {
  border-top: 0 !important;
  background-color: #ffffff0d !important;
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: #ffffff !important;
}

.ant-btn-primary {
  background: #262d4e !important;
  border-radius: 8px !important;
  border-color: transparent !important;
}

.ant-btn-sm {
  height: 32px !important;
  padding: 4px 11px !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: transparent !important;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background-color: transparent !important;
}

.ant-checkbox-inner {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border: 0px !important;
}

/* .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #ffffff !important;
  background-color: #303961 !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #262d4e !important;
  color: #ffffff !important;
} */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(178, 186, 203, 0.5);
  border-radius: 3px;
}
