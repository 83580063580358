.LeftMenu {
  height: 100%;

  // :global {
  //   .ant-menu {
  //     background-color: #2d304a;
  //     font-weight: 400;
  //     font-size: 14px;
  //     line-height: 20px;
  //     user-select: none;
  //     color: #ffffff;

  //     .ant-menu-item-only-child {
  //       margin: 0;
  //       padding: 38px 46px !important;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //     }

  //     .ant-menu-item-selected {
  //       color: #f7f6fc;
  //       background-color: #2483ff;
  //       border-top-right-radius: 6px;
  //       border-bottom-right-radius: 6px;
  //     }
  //   }
  // }

  // :global {
  //   .ant-menu {
  //     background-color: #fcfeff;
  //     font-weight: 600;
  //     font-size: 20px;
  //     line-height: 27px;
  //     user-select: none;

  //     .ant-menu-item-only-child {
  //       margin: 0;
  //       padding: 38px 46px !important;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //     }

  //     .ant-menu-item-selected {
  //       color: #f7f6fc;
  //       background-color: #2483ff;
  //       border-top-right-radius: 6px;
  //       border-bottom-right-radius: 6px;
  //     }
  //   }

  //   .ant-menu-inline {
  //     color: #292b2eb2;
  //     border: none;
  //   }

  //   .ant-menu-item::after {
  //     display: none;
  //   }

  //   .ant-menu-item:hover {
  //     color: #2483ff;
  //     border-top-right-radius: 6px;
  //     border-bottom-right-radius: 6px;
  //   }
  //   .ant-menu-item-selected:hover {
  //     color: #ffffff;
  //     background-color: #2483ff;
  //     border-top-right-radius: 6px;
  //     border-bottom-right-radius: 6px;
  //   }
  // }
}

